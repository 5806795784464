import jsonData from "../../data/json/sentences.json";
import React, { useState, useEffect } from "react";
import "./App.css";
// import { useTranslation } from "react-i18next";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import Header from "../Header/header";
import Photo from "../Photo/photo";
import Message from "../Message/message";

function App() {
  const [sentence, setSentence] = useState({});
  const [clicked, setClicked] = useState(false);
  const [language, setLanguage] = useState(true);

  useEffect(() => {
    generateSentence();
  }, [language]);

  // const { t } = useTranslation();
  const terroristImageContext = require.context(
    "../../assets/images/terrorists",
    false,
    /\.jpg$/
  );

  const initialize = () => {
    setClicked(true);
  };

  const randomItem = (items) => {
    return items[Math.floor(Math.random() * items.length)];
  };

  const getTerrorist = () => {
    let imagePaths = terroristImageContext.keys().map((key) => {
      return terroristImageContext(key);
    });
    return randomItem(imagePaths);
  };

  const changeLanguage = (e) => {
    e.preventDefault();
    setLanguage(!language);
  };

  const generateSentence = () => {
    // TODO: set this dynamically, currently locked to hebrew
    let data = jsonData[language ? "he" : "en"];

    setSentence({
      he: `${randomItem(data["rank"])} ${randomItem(
        data["first_name"]
      )} ${randomItem(data["last_name"])}, מפקד מערך ${randomItem(
        data["force"]
      )} ב${randomItem(data["area"])}`,
      en: `${randomItem(data["rank"])} ${randomItem(
        data["first_name"]
      )} ${randomItem(data["last_name"])}, head of ${randomItem(
        data["force"]
      )} in ${randomItem(data["area"])}`,
    });
  };

  return (
    <div className="App">
      <Header
        generateSentence={generateSentence}
        language={language}
        changeLanguage={changeLanguage}
        initialize={initialize}
        start={clicked}
      />
      <Message sentence={sentence} clicked={clicked} language={language} />
      <Photo getTerrorist={getTerrorist} clicked={clicked} />
      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;
