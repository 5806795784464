import React from "react";
import "./message.css";
import moment from "moment/moment";
import "moment/locale/he";
import { toJewishDate, formatJewishDateInHebrew } from "jewish-date";

function Message({ sentence, clicked, language }) {
  const setDateHE = () => {
    moment.locale("he");
    const m = moment();
    const d = new Date();
    const jewishDate = toJewishDate(d);
    return ` ${m.format("\u200FLL").toString()}, ${formatJewishDateInHebrew(
      jewishDate
    )}
    `;
  };

  const setDateEn = () => {
    moment.locale("en");
    const m = moment();
    return m.format("LL").toString();
  };

  const content = (place) => {
    return place === "status"
      ? language
        ? "חוסל"
        : "Liquidated"
      : language
      ? sentence.he
      : sentence.en;
  };

  return (
    <div className={`hide ${clicked && "body"}`}>
      <p className="date">{language ? setDateHE() : setDateEn()}</p>
      <h1 className="status">{content("status")}</h1>
      <p className={`message-text ${!language && "message-text-en"}`}>
        {content("sentence")}
      </p>
    </div>
  );
}

export default Message;
