import React from "react";
import "./header.css";
import idf from "../../assets/images/idf.png";
import shbk from "../../assets/images/shbk.png";
import spk from "../../assets/images/Spokesperson.png";

function Header({
  generateSentence,
  language,
  changeLanguage,
  initialize,
  start,
}) {
  const clicked = (e) => {
    e.preventDefault();
    initialize();
    generateSentence();
  };

  return (
    <header className={`header ${!start && "header-unclicked"}`}>
      <div>
        <img src={spk} className="logos" alt="spokesperson logo" />
        <img src={idf} className="logos" alt="idf logo" />
        <img src={shbk} className="logos" alt="shin bet logo" />
      </div>
      <button className="button" onClick={clicked}>
        {language ? "עדכון אחרון" : "Latest update"}
      </button>
      <button className="language" onClick={changeLanguage}>
        {language ? "En" : "He"}
      </button>
    </header>
  );
}

export default Header;
