import React from "react";
import "./photo.css";

function Photo({ getTerrorist, clicked }) {
  return (
    <div className={"photo"}>
      {clicked && (
        <img className="photo-clicked" src={getTerrorist()} alt="Terrorist" />
      )}
    </div>
  );
}

export default Photo;
